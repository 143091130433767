import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getProfile(): Observable<any> {
    return this.http.get(`${this.apiUrl}/profile`);
  }

  updateProfile(data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/profile`, data);
  }

  getFilterOptions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/public/homepage/get-filter-option`);
  }

  getBrands(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/public/homepage/get-brand-list?${params}`);
  }

  getBrand(id: any, role: string = 'public'): Observable<any> {
    return this.http.get(`${this.apiUrl}/${role}/brand/${id}`);
  }

  getMultipleBrands(params: any, role: string = 'public'): Observable<any> {
    return this.http.get(`${this.apiUrl}/${role}/brand/multiple-brands?${params}`);
  }

  getOrders(params?: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/order?${params}`, {});
  }

  getOrder(uid: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/order/${uid}`);
  }

  requestBrand(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/public/request-brand`, data);
  }

  getWishlist(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/wishlist`);
  }

  addToWishlist(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/wishlist`, data);
  }

  deleteFromWishlist(uid: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/wishlist/${uid}`);
  }

  getPaymentOptions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/payment-method`);
  }

  upload(formData: FormData): Observable<any> {
    // const boundary = '--------------------------' + Math.random().toString().substring(2);
    // const headers = new HttpHeaders({
    //   'Content-Type': `multipart/form-data; boundary=${boundary}`,
    // });
    // return this.http.post(`https://httpbin.org/post`, formData, { headers });
    return this.http.post(`${this.apiUrl}/private/upload`, formData);
  }
}
